import React from "react";
import ReactDOM from "react-dom";
import { bugsnag } from "./bugsnag";
import App from "./App";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import { colors } from "./colors";
import createPalette from "@material-ui/core/styles/createPalette";

const ErrorBoundary = bugsnag.getPlugin("react")!.createErrorBoundary(React);

const headerFontFamily = {
  fontFamily: ["Josefin Sans", "sans-serif"].join(","),
};

const normalFontFamily = ["Poppins", "sans-serif"];

const paletteOptions: Parameters<typeof createPalette>[0] = {
  primary: { main: colors.primary, contrastText: "#fff" },
  secondary: { main: colors.secondary, contrastText: "#fff" },
  text: { primary: "#fff", secondary: colors.surface },
  background: { paper: colors.surface },
};

const palette = createPalette(paletteOptions);

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: paletteOptions,
    typography: {
      fontFamily: normalFontFamily.join(", "),
      h1: headerFontFamily,
      h2: headerFontFamily,
      h3: headerFontFamily,
      h4: headerFontFamily,
      h5: headerFontFamily,
    },
    props: {
      MuiButton: {
        variant: "contained",
      },
      MuiTextField: {
        color: "secondary",
        variant: "filled",
      },
      MuiFilledInput: {
        disableUnderline: true,
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "@font-face": {
            fontFamily: normalFontFamily.join(","),
          },
        },
      },
      MuiButton: {
        root: {
          fontWeight: "bold",
        },
        contained: {
          borderRadius: 19,
        },
        sizeLarge: {
          height: 62,
          fontSize: 21,
        },
        containedPrimary: {
          border: "3px solid #FFDFAF",
          boxShadow: "3px 5px #9E7941",
          "&$disabled": {
            backgroundColor: palette.primary.dark,
          },
        },
        containedSecondary: {
          border: "3px solid #B0DDF6",
          boxShadow: "3px 5px #3F7A9B",
        },
        disabled: {},
      },
      MuiInputBase: {
        root: {},
      },
      MuiFilledInput: {
        root: {
          color: "black",
          backgroundColor: "white",
          // borderTopLeftRadius: 0,
          // borderTopRightRadius: 20,
          // borderBottomRightRadius: 0,
          // borderBottomLeftRadius: 20,

          "&$focused": {
            backgroundColor: "white",
          },
          "&:hover": {
            backgroundColor: "white",
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: colors.primary,
          "&$focused": {
            color: colors.primary,
          },
        },
      },
      MuiOutlinedInput: {
        input: {
          background: colors.surfaceLighter,
          borderRadius: 25,
        },
        notchedOutline: {
          borderColor: "white",
          borderRadius: 25,
          borderWidth: 3,
        },
      },
    },
  })
);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <CssBaseline />
        <App />
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
