import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { CustomCard } from "../components/CustomCard";
import { PageTextHeader } from "../components/PageHeader";
import { RegisterErrorCode, sessionController } from "../generated/rpc";
import { routes } from "../routes";
import { useRpc } from "../rpc/runtime";
import { wait } from "../wait";

const registerErrors: {
  [key in RegisterErrorCode]?: {
    emailError?: string;
    passwordError?: string;
  };
} = {
  [RegisterErrorCode.EmailInUse]: {
    emailError: "E-posten är redan registrerad",
  },
  [RegisterErrorCode.InsufficientPassword]: {
    passwordError: "Otillräckligt lösenord",
  },
  [RegisterErrorCode.InvalidEmail]: {
    emailError: "Ogiltig e-post",
  },
  [RegisterErrorCode.MissingEmail]: {
    emailError: "En e-postadress är obligatorisk",
  },
  [RegisterErrorCode.MissingPassword]: {
    passwordError: "Ett lösenord krävs",
  },
};

export const RegisterPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [creating, setLoading] = useState(false);
  const history = useHistory();

  const register = useRpc(sessionController.register, { throwOnError: true });

  const submit = async () => {
    try {
      setLoading(true);

      const result = await register.invoke({
        email,
        password,
      });

      if (result.$type === "RegisterSuccess") {
        history.replace(routes.words.path);
      }
    } catch (error) {
      // error is handled via the error property on "register"
    } finally {
      await wait(500);
      setLoading(false);
    }
  };

  const error = register.error;

  const { emailError, passwordError } = (register.returnValue?.$type ===
    "RegisterError" &&
    registerErrors[register.returnValue.code]) || {
    emailError: undefined,
    passwordError: undefined,
  };

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <PageTextHeader heading="Skapa konto"></PageTextHeader>

      <Container maxWidth="sm">
        <CustomCard>
          <Box p={3}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={(ev) => {
                ev.preventDefault();
                submit();
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    label="E-post"
                    fullWidth
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                    autoComplete="username"
                    disabled={creating}
                    error={!!emailError}
                    helperText={emailError}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Lösenord"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                    autoComplete="new-password"
                    disabled={creating}
                    error={!!passwordError}
                    helperText={passwordError}
                  ></TextField>
                </Grid>
                {error ? (
                  <Grid item xs={12}>
                    <Typography color="error">{error.code}</Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={creating}
                      >
                        Skapa konto
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </CustomCard>

        <Box mt={5} textAlign="center">
          <Typography color="textSecondary" variant="body2">
            Har du ett konto?{" "}
            <Link color="primary" component={RouterLink} to={routes.login.path}>
              Logga in
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
