import { Box, Container, Hidden, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { HiMenu } from "react-icons/hi";
import {
  BrowserRouter as Router,
  NavLink,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import { colors } from "./colors";
import { config } from "./config";
import { AppContext, defaultAppContextValue, useAppContext } from "./context";
import { sessionController, SessionResult } from "./generated/rpc";
import { AboutPage } from "./pages/AboutPage";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { LandingPage } from "./pages/LandingPage";
import { LetterIndexPage } from "./pages/LetterPage";
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import { WordPage } from "./pages/WordPage";
import { WordsPage } from "./pages/WordsPage";
import { routes } from "./routes";
import {
  fetchJsonRpc,
  RpcClient,
  RpcClientContext,
  useRpcClient,
} from "./rpc/runtime";

const useStyles = makeStyles({
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    color: "#fff",
    background: "#fff",
  },
});

function App() {
  const classes = useStyles();

  const [appContextValue, setAppContextValue] = useState(
    defaultAppContextValue
  );
  const [rpcClient] = useState<RpcClient>(() => ({
    dispatch: (invocation) =>
      fetchJsonRpc(invocation, config.serverUrl + "/rpc"),
  }));

  useEffect(() => {
    (async () => {
      var initialized = false;

      // Backup if fetch session does not return in time
      setTimeout(() => {
        if (!initialized) {
          setAppContextValue({
            user: null,
            update: setAppContextValue,
            cache: {},
          });
        }
      }, 1000);

      var result: SessionResult;
      try {
        result = await rpcClient.dispatch(sessionController.fetchSession());
      } catch (error) {
        console.error("Failed to fetch session", error);
        setAppContextValue({
          user: null,
          update: setAppContextValue,
          cache: {},
        });
        return;
      }

      setAppContextValue({
        user: result.$type === "NoSessionResult" ? null : { id: result.userId },
        update: setAppContextValue,
        cache: {},
      });
      initialized = true;
    })();
  }, [rpcClient]);

  if (appContextValue === defaultAppContextValue) {
    return null;
  }

  return (
    <RpcClientContext.Provider value={rpcClient}>
      <AppContext.Provider value={appContextValue}>
        <Router>
          <Box
            className={classes.container}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
          >
            <Switch>
              <Route exact path="/">
                <SiteNavigation />
                <LandingPage />
              </Route>
              <Route path={routes.login.path}>
                <SiteNavigation />
                <LoginPage />
              </Route>
              <Route path={routes.about.path}>
                <SiteNavigation />
                <AboutPage />
              </Route>
              <Route path={routes.register.path}>
                <SiteNavigation />
                <RegisterPage />
              </Route>
              <Route path={routes.forgotPassword.path}>
                <SiteNavigation />
                <ForgotPasswordPage />
              </Route>
              <PremiumRoute
                path={routes.letter.path}
                component={LetterIndexPage}
              />
              <PremiumRoute path={routes.word.path} component={WordPage} />
              <PremiumRoute path={routes.words.path} component={WordsPage} />
            </Switch>
          </Box>
        </Router>
      </AppContext.Provider>
    </RpcClientContext.Provider>
  );
}

export function PremiumRoute({ children, component, ...rest }: RouteProps) {
  let appContext = useAppContext();
  const passed = appContext.user;
  return (
    <Route
      {...rest}
      component={passed ? component : undefined}
      render={({ location }) =>
        passed ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.login.path,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const useNavigationStyle = makeStyles((theme) => ({
  mobileMenuLink: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
    fontSize: 20,
    padding: 10,
  },
  mobileMenuActiveLink: {},
  fullMenuLink: {
    background: "transparent",
    borderBottomRightRadius: 19,
    borderBottomLeftRadius: 19,
    boxShadow: "none",
    paddingTop: 30,
    paddingBottom: 8,
    paddingLeft: 15,
    paddingRight: 15,
    color: theme.palette.text.secondary,
    fontWeight: "bold",
    fontSize: 24,
    textTransform: "none",
    textDecoration: "none",
    marginLeft: 30,
    marginRight: 30,
    "&:hover": {
      textDecoration: "underline",
    },

    width: 140,
    textAlign: "center",
  },
  fullMenuActiveLink: {
    border: "1px solid #eee",
    borderTop: "none",
    background: colors.secondary,
    color: "#fff",
    fontWeight: "bold",
  },
  container: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
      marginBottom: 25,
    },
  },
  logo: {
    border: "1px dashed gray",
    width: 50,
    height: 50,
    borderRadius: 25,
    color: "gray",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 5,
    left: 15,
    [theme.breakpoints.down("sm")]: {
      width: 36,
      height: 36,
      borderRadius: 18,
    },
  },
  hamburgerMenuButton: {
    outline: "none",
    background: theme.palette.secondary.main,
    borderRadius: 5,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    border: "none",
    zIndex: 2,
    width: 55,
    height: 55,
    paddingTop: 10,
    "& svg": {
      width: "100%",
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      width: 45,
      height: 45,
    },
  },

  mobileMenuContainer: (props: any) => ({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    background: "white",
    transition: "transform 200ms",
    transform: !props.showMobileMenu ? "translateY(-105%)" : "translateY(0%)",
    boxShadow: "0px 0px 5px #00000036",
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ddd",
    zIndex: 1,
  }),
}));

const SiteNavigation = () => {
  const context = useAppContext();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const classes = useNavigationStyle({ showMobileMenu });

  return (
    <Container className={classes.container}>
      <div className={classes.logo}>S</div>
      <Hidden mdUp>
        <div className={classes.mobileMenuContainer}>
          <NavigationLinks
            loggedIn={!!context.user}
            activeLinkClassName={classes.mobileMenuActiveLink}
            linkClassName={classes.mobileMenuLink}
          />
        </div>
        <button
          className={classes.hamburgerMenuButton}
          onClick={() => setShowMobileMenu((p) => !p)}
        >
          <HiMenu color="white" />
        </button>
      </Hidden>
      <Hidden smDown>
        <NavigationLinks
          loggedIn={!!context.user}
          activeLinkClassName={classes.fullMenuActiveLink}
          linkClassName={classes.fullMenuLink}
        />
      </Hidden>
    </Container>
  );
};

const NavigationLinks = (props: {
  linkClassName: string;
  activeLinkClassName: string;
  loggedIn: boolean;
}) => {
  const appContext = useAppContext();
  const rpcClient = useRpcClient();
  return (
    <>
      <NavLink
        to="/"
        exact
        activeClassName={props.activeLinkClassName}
        className={props.linkClassName}
      >
        Hem
      </NavLink>
      <NavLink
        to={routes.about.path}
        activeClassName={props.activeLinkClassName}
        className={props.linkClassName}
      >
        Om oss
      </NavLink>
      {!props.loggedIn ? (
        <>
          <NavLink
            to={routes.login.path}
            className={props.linkClassName}
            activeClassName={props.activeLinkClassName}
          >
            Logga in
          </NavLink>
          <NavLink to={routes.words.path} className={props.linkClassName}>
            Testa nu
          </NavLink>
        </>
      ) : (
        <>
          <NavLink
            to="/"
            className={props.linkClassName}
            color="inherit"
            onClick={async (ev) => {
              ev.preventDefault();
              rpcClient.dispatch(sessionController.logout());
              appContext.update((p) => ({ ...p, user: null }));
            }}
          >
            Logga ut
          </NavLink>
          <NavLink to={routes.words.path} className={props.linkClassName}>
            Starta
          </NavLink>
        </>
      )}
    </>
  );
};

export default App;
