import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { CustomCard } from "../components/CustomCard";
import { PageTextHeader } from "../components/PageHeader";
import { useAppContext } from "../context";
import { sessionController } from "../generated/rpc";
import { routes } from "../routes";
import { useRpc } from "../rpc/runtime";

const useLoginPageStyles = makeStyles((theme) => ({
  focused: {
    backgroundColor: "pink",
  },
}));

export interface LoginPageHistoryState {
  from: string;
}

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const classes = useLoginPageStyles();

  const authenticate = useRpc(sessionController.login);
  const appContext = useAppContext();
  const history = useHistory<LoginPageHistoryState>();

  const submit = async () => {
    const loginResult = await authenticate.invoke(email, password);
    if (loginResult.$type === "LoginSuccess") {
      appContext.update((p) => ({ ...p, user: { id: loginResult.userId } }));
      history.replace(history.location.state?.from ?? routes.words.path);
    }
  };

  const loggingIn = authenticate.invoking;
  const error = authenticate.error;

  return (
    <Box flex={1} display="flex" flexDirection="column">
      <PageTextHeader heading="Logga in"></PageTextHeader>
      <Container maxWidth="sm">
        <CustomCard>
          <Box p={3}>
            <form
              noValidate
              autoComplete="off"
              onSubmit={(ev) => {
                ev.preventDefault();
                submit();
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <TextField
                    label="E-post"
                    fullWidth
                    color="primary"
                    value={email}
                    onChange={(ev) => setEmail(ev.target.value)}
                    autoComplete="username"
                    disabled={loggingIn}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Lösenord"
                    type="password"
                    fullWidth
                    value={password}
                    onChange={(ev) => setPassword(ev.target.value)}
                    autoComplete="password"
                    disabled={loggingIn}
                  ></TextField>
                </Grid>
                {error ? (
                  <Grid item xs={12}>
                    <Typography color="error">{error.message}</Typography>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <Grid
                    container
                    justify="flex-start"
                    alignItems="center"
                    spacing={4}
                    direction="row-reverse"
                  >
                    <Grid item sm="auto">
                      <Button
                        color="primary"
                        disabled={loggingIn}
                        type="submit"
                      >
                        Logga in
                      </Button>
                    </Grid>
                    <Grid item sm="auto">
                      <Link
                        component={RouterLink}
                        to={routes.forgotPassword.path}
                      >
                        Glömt lösenordet?
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Box>
        </CustomCard>

        <Box mt={5} textAlign="center">
          <Typography color="textSecondary" variant="body2">
            Saknar du konto?{" "}
            <Link
              color="primary"
              component={RouterLink}
              to={routes.register.path}
            >
              Skapa här
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};
