import { Box, Typography } from "@material-ui/core";
import React from "react";

export const ErrorBox = (props: { children: React.ReactNode }) => {
  return (
    <Box>
      <Typography color="error">{props.children}</Typography>
    </Box>
  );
};
