import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
  makeStyles,
  Grid,
} from "@material-ui/core";
import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Word, WordExample } from "../types";
import { ErrorBox } from "../components/ErrorBox";
import { useRpcFetch } from "../rpc/runtime";
import { wordController } from "../generated/rpc";
import { LookupHeader } from "../components/LookupHeader";
import { colors } from "../colors";
import { CustomCard } from "../components/CustomCard";
import illuPhSrc from "../images/illu-ph.png";

const useStyles = makeStyles({
  header: {
    background: colors.surface,
    width: "auto",
    textTransform: "capitalize",
    display: "inline-block",
    margin: "0 auto",
    padding: 15,
    borderRadius: 5,
    marginBottom: 15,
    marginTop: 15,
  },
  illustration: {
    maxWidth: "100%",
  },
  inactiveButton: {
    background: colors.surface,
  },
});

export const WordPage = (props: RouteComponentProps<{ wordId: string }>) => {
  const wordId = props.match.params.wordId;

  const [selectedExampleName, setSelectedExampleName] = useState<string | null>(
    null
  );

  const { result: word, fetching, error } = useRpcFetch(
    wordController.getWord,
    wordId
  );
  const classes = useStyles();

  if (fetching) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorBox>Något gick fel när ordet skulle hämtas</ErrorBox>;
  }

  if (!word) {
    return <Typography>Ordet finns inte</Typography>;
  }

  const selectedExample = word.examples.find(
    (x) => x.name === selectedExampleName
  );

  return (
    <>
      <LookupHeader></LookupHeader>
      <h1 className={classes.header}>{word.name}</h1>
      <Container>
        <Box mb={2}>
          <Button
            className={
              !!selectedExampleName ? classes.inactiveButton : undefined
            }
            color={!selectedExampleName ? "primary" : "secondary"}
            onClick={() => setSelectedExampleName(null)}
          >
            Förklaring
          </Button>
          {word.examples.map((ex) => (
            <Box ml={2} key={ex.name} clone>
              <Button
                className={
                  ex.name !== selectedExampleName
                    ? classes.inactiveButton
                    : undefined
                }
                color={
                  ex.name === selectedExampleName ? "primary" : "secondary"
                }
                onClick={() => setSelectedExampleName(ex.name)}
              >
                {ex.name}
              </Button>
            </Box>
          ))}
        </Box>
        <CustomCard noPipe>
          <Grid container>
            <Grid item md={6}>
              <Box
                flex={1}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography component="h2" variant="h5" gutterBottom>
                  {selectedExample?.name ?? "Förklaring:"}
                </Typography>
                <Typography align="center" paragraph>
                  {selectedExample?.text ?? word.explanation}
                </Typography>
              </Box>
            </Grid>

            <Grid item md={6}>
              <img
                src={illuPhSrc}
                alt="Illustration"
                className={classes.illustration}
              ></img>
            </Grid>
          </Grid>
        </CustomCard>
      </Container>
    </>
  );
};

const useButtonStyles = makeStyles({
  root: {
    width: 200,
    margin: 8,
  },
});

const ExampleButton = ({ example }: { example: WordExample }) => {
  const [show, setShow] = useState(false);
  const classes = useButtonStyles();

  return (
    <>
      <Button
        color="primary"
        size="large"
        variant="contained"
        className={classes.root}
        onClick={() => setShow(true)}
      >
        {example.name}
      </Button>
      <Dialog open={show} onClose={() => setShow(false)}>
        <DialogTitle>{example.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>{example.text}</DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
