// tslint:disable
import type { RpcInvocation } from "../rpc/runtime";

export type SessionResult = UserSession | NoSessionResult;

export type UserSession = {
  $type: "UserSession";
  userId: number;
};

export type NoSessionResult = {
  $type: "NoSessionResult";

};

export type LoginResult = LoginFailed | LoginSuccess;

export type LoginFailed = {
  $type: "LoginFailed";

};

export type LoginSuccess = {
  $type: "LoginSuccess";
  userId: number;
};

export type RegisterInput = {
  email: string;
  password: string;
};

export type RegisterResponse = RegisterError | RegisterSuccess;

export type RegisterError = {
  $type: "RegisterError";
  code: RegisterErrorCode;
};

export enum RegisterErrorCode {
  MissingEmail = "MissingEmail",
  InvalidEmail = "InvalidEmail",
  EmailInUse = "EmailInUse",
  MissingPassword = "MissingPassword",
  InsufficientPassword = "InsufficientPassword"
}

export type RegisterSuccess = {
  $type: "RegisterSuccess";

};

export type WordListItem = {
  id: string;
  name: string;
};

export type Word = {
  examples: Array<WordExample>;
  explanation: string;
  id: string;
  name: string;
};

export type WordExample = {
  name: string;
  text: string;
};

export const sessionController = {
  fetchSession: function (...args: []): RpcInvocation<SessionResult> {
    return [{ id: Math.random().toString(), service: 'SessionController', proc: 'FetchSession'}, ...args];
  },
  logout: function (...args: []): RpcInvocation<void> {
    return [{ id: Math.random().toString(), service: 'SessionController', proc: 'Logout'}, ...args];
  },
  login: function (...args: [emailOrUsername: string, password: string]): RpcInvocation<LoginResult> {
    return [{ id: Math.random().toString(), service: 'SessionController', proc: 'Login'}, ...args];
  },
  register: function (...args: [input: RegisterInput]): RpcInvocation<RegisterResponse> {
    return [{ id: Math.random().toString(), service: 'SessionController', proc: 'Register'}, ...args];
  },
}

export const wordController = {
  getWordList: function (...args: []): RpcInvocation<Array<WordListItem>> {
    return [{ id: Math.random().toString(), service: 'WordController', proc: 'GetWordList'}, ...args];
  },
  getWord: function (...args: [id: string]): RpcInvocation<Word> {
    return [{ id: Math.random().toString(), service: 'WordController', proc: 'GetWord'}, ...args];
  },
}

