import { Box, Container, makeStyles } from "@material-ui/core";
import React from "react";
import { colors } from "../colors";

export const PageHeader = (props: { children: React.ReactNode }) => {
  return (
    <Box>
      <Box bgcolor={colors.surface} p={3}>
        {props.children}
      </Box>
      <Box mt={0.5} height={8} bgcolor={colors.primary}></Box>
    </Box>
  );
};

const usePageStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "bold",
    fontSize: 25,
    textAlign: "center",
  },
}));

export const PageTextHeader = (props: { heading: string }) => {
  const classes = usePageStyles();

  return (
    <PageHeader>
      <Container maxWidth="md" className={classes.heading}>
        {props.heading}
      </Container>
    </PageHeader>
  );
};
