export const routes = {
  login: {
    path: "/logga-in",
  },
  register: {
    path: "/registrera",
  },
  about: {
    path: "/om",
  },
  forgotPassword: {
    path: "/glomt-losen",
  },
  letter: {
    path: "/bokstav/:letter",
  },
  word: {
    path: "/ord/:wordId",
  },
  words: {
    path: "/ord",
  },
};
