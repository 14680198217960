import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../routes";
import { authErrorMessages } from "./authErrorMessages";

export const ForgotPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [requesting, setRequesting] = useState(false);
  const [error, setError] = useState("");
  const [completed, setCompleted] = useState(false);

  const submit = async () => {};

  return (
    <Box
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Container maxWidth="sm">
        <Typography variant="h2" gutterBottom align="center">
          Glömt lösenordet?
        </Typography>

        <Card>
          <Box p={3}>
            {completed ? (
              <Box>
                <Typography paragraph>
                  Ett mail har skickats till din e-post för att sätta ett nytt
                  lösenord.
                </Typography>
                <Link
                  color="primary"
                  component={RouterLink}
                  to={routes.login.path}
                >
                  Till inloggningen
                </Link>
              </Box>
            ) : (
              <form
                noValidate
                autoComplete="off"
                onSubmit={(ev) => {
                  ev.preventDefault();
                  submit();
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="E-post"
                      fullWidth
                      variant="outlined"
                      value={email}
                      onChange={(ev) => setEmail(ev.target.value)}
                      autoComplete="username"
                      disabled={requesting}
                    ></TextField>
                  </Grid>
                  {error ? (
                    <Grid item xs={12}>
                      <Typography color="error">{error}</Typography>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Grid
                      container
                      justify="flex-end"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid item sm="auto">
                        <Button
                          color="primary"
                          size="large"
                          variant="contained"
                          disabled={requesting}
                          onClick={submit}
                        >
                          Återställ
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Box>
        </Card>
      </Container>
    </Box>
  );
};
