import { createContext, useContext, useEffect, useMemo, useState } from "react";

export const errorCodes = {
  serverError: "server_error",
  networkError: "network_error",
  responseFormatError: "response_format_error",
};

export class RpcError extends Error {
  constructor(public code: string, message?: string) {
    super(message);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type RpcInvocation<TReturn> = [
  { id: string; service: string; proc: string },
  ...any[]
];

export type RpcResponse<TReturn> =
  | RpcErrorResponse
  | RpcReturnValueResponse<TReturn>;

export type RpcErrorResponse = [
  { id: string; errorCode: string; errorMessage?: string },
  any
];

export type RpcReturnValueResponse<TReturn> = [{ id: string }, TReturn];

export function isRpcResponse(response: any): response is RpcResponse<any> {
  if (!Array.isArray(response)) {
    return false;
  }

  if (response.length < 1) {
    return false;
  }

  const header = response[0];
  if (typeof header !== "object") {
    return false;
  }

  return true;
}

export const typedJsonReviver: (
  this: any,
  key: string,
  value: any
) => any = function (key, value) {
  if (typeof value === "object" && typeof value.$value === "object") {
    return value.$value;
  } else if (
    typeof value === "object" &&
    typeof this === "object" &&
    !Array.isArray(this) &&
    !Array.isArray(value) &&
    key.startsWith("$")
  ) {
    value.$type = key.substr(1);
    this.$value = value;
    return value;
  }

  return value;
};

export const typedJsonReplacer: (
  this: any,
  key: string,
  value: any
) => any = function (key, value) {
  if (Array.isArray(value)) {
    return value;
  }

  if (typeof value === "object" && value["$type"]) {
    const clone = { ...value };
    delete clone["$type"];
    return {
      ["$" + value["$type"]]: clone,
    };
  }

  return value;
};

export interface RpcHookOptions {
  throwOnError?: boolean;
}

type RpcReturnType<
  TRpc extends (...args: any[]) => RpcInvocation<any>
> = TRpc extends (...args: any[]) => RpcInvocation<infer R> ? R : any;

export async function fetchJsonRpc<TResult>(
  invocation: RpcInvocation<TResult>,
  url: string
) {
  const response = await fetch(url, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(invocation, typedJsonReplacer),
  });

  if (!response.ok) {
    throw new RpcError(errorCodes.serverError);
  }

  const json = await response.text();

  let rpcResponse: any;
  try {
    rpcResponse = JSON.parse(json, typedJsonReviver);
  } catch (error) {
    throw new RpcError(errorCodes.responseFormatError);
  }

  if (!isRpcResponse(rpcResponse)) {
    throw new RpcError(errorCodes.responseFormatError);
  }

  const rpcHeader = rpcResponse[0];
  if ("errorCode" in rpcHeader) {
    throw new RpcError(rpcHeader.errorCode, rpcHeader.errorMessage);
  }

  return rpcResponse[1];
}

export interface RpcClient {
  dispatch<TResult>(invocation: RpcInvocation<TResult>): Promise<TResult>;
}

export const RpcClientContext = createContext<RpcClient>({
  dispatch: () => {
    throw new Error("RPC Client not configured");
  },
});

export function useRpcClient() {
  return useContext(RpcClientContext);
}

export function useRpc<TRpc extends (...args: any[]) => RpcInvocation<any>>(
  action: TRpc,
  options?: RpcHookOptions
) {
  const [invoking, setInvoking] = useState(false);
  const [error, setError] = useState<RpcError | undefined>(undefined);
  const [returnValue, setReturnValue] = useState<
    RpcReturnType<TRpc> | undefined
  >(undefined);
  const rpcClient = useContext(RpcClientContext);

  const invoke = useMemo(
    () => async (...args: Parameters<TRpc>): Promise<RpcReturnType<TRpc>> => {
      setInvoking(true);
      setError(undefined);
      try {
        const invocation = action(...args);
        const returnValue = await rpcClient.dispatch(invocation);
        setReturnValue(returnValue);
        return returnValue;
      } catch (error) {
        const rpcError =
          error instanceof RpcError
            ? error
            : new RpcError(errorCodes.networkError);
        setError(rpcError);

        if (options?.throwOnError) {
          throw rpcError;
        }

        return new Promise(() => {});
      } finally {
        setInvoking(false);
      }
    },
    [rpcClient, action, options?.throwOnError]
  );

  return {
    invoking,
    error,
    returnValue,
    invoke,
    // Useful for optimistic updates
    setReturnValue,
  };
}

export function useRpcFetch<
  TRpc extends (...args: any[]) => RpcInvocation<any>
>(action: TRpc, ...args: Parameters<TRpc>) {
  const [fetched, setQueried] = useState(false);
  const { invoking: fetching, returnValue, error, invoke } = useRpc(action);

  useEffect(
    () => {
      invoke(...args);
      setQueried(true);
    },
    // eslint-disable-next-line
    args
  );

  return {
    fetching,
    result: returnValue,
    error,
    refetch: invoke,
    fetched,
  };
}
