import { Box, Container } from "@material-ui/core";
import React from "react";
import { CustomCard } from "../components/CustomCard";
import { PageTextHeader } from "../components/PageHeader";

export const AboutPage = () => {
  return (
    <Box flex={1} display="flex" flexDirection="column">
      <PageTextHeader heading="Om oss"></PageTextHeader>
      <Container maxWidth="sm">
        <CustomCard>Information om Simpli och Cooke Education</CustomCard>
      </Container>
    </Box>
  );
};
