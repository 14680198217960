import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

export const bugsnag = Bugsnag.start({
  apiKey: "9eeac0290c0fc34cd629cfca1b8349cb",
  plugins: [new BugsnagPluginReact()],
  collectUserIp: false,
  autoTrackSessions: true,
  enabledReleaseStages: ["production"],
});
