import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { colors } from "../colors";

const pipeHeight = 60;

export const CustomCard = (props: {
  children: React.ReactNode;
  noPipe?: boolean;
}) => {
  return (
    <Box
      bgcolor={colors.surface}
      p={3}
      position="relative"
      style={{ marginTop: props.noPipe ? undefined : pipeHeight }}
    >
      {!props.noPipe ? (
        <Box position="absolute" top={0} left={0} right={0} zIndex={0}>
          <Box
            bgcolor={colors.surface}
            width={12}
            height={pipeHeight}
            position="absolute"
            bottom={0}
            left="50%"
          ></Box>
        </Box>
      ) : null}
      {props.children}
    </Box>
  );
};
