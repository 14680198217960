import { useEffect } from "react";
import { useAppContext } from "../context";
import { wordController, WordListItem } from "../generated/rpc";
import { useRpc } from "../rpc/runtime";

export const useFetchWords = () => {
  const { cache, update } = useAppContext();
  const { invoking: fetching, returnValue, error, invoke } = useRpc(
    wordController.getWordList
  );

  const cacheResult: WordListItem[] | undefined = cache["words"];

  useEffect(() => {
    if (cacheResult === undefined && !fetching && !error) {
      invoke();
    }
  }, [invoke, cacheResult, fetching, error]);

  useEffect(() => {
    if (returnValue !== undefined && !error) {
      update((p) => ({ ...p, cache: { ...p.cache, words: returnValue } }));
    }
  }, [returnValue, update, error]);

  return {
    fetching,
    result: cacheResult ?? returnValue,
    error,
    refetch: invoke,
  };
};
