const defaultConfig = {
  serverUrl: "http://localhost:5000",
};

const configMap: { [key: string]: Partial<typeof defaultConfig> } = {
  "http://192.168.1.52:3000": {
    serverUrl: "http://192.168.1.52:5000",
  },
  "http://192.168.1.43:3000": {
    serverUrl: "http://192.168.1.43:5000",
  },
};

export const config = {
  ...defaultConfig,
  ...configMap[window.location.origin],
};

export const serverUrl = config.serverUrl;
