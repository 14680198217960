import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { colors } from "../colors";
import { routes } from "../routes";
import { ReactComponent as ScreenAndBook } from "../images/book-and-screen.svg";
import { useAppContext } from "../context";

export const LandingPage = () => {
  const classes = useLandingStyles();
  const history = useHistory();
  const appContext = useAppContext();

  return (
    <Box display="flex" alignItems="stretch" flexDirection="column">
      <div className={classes.imageSpacer}></div>
      <Box
        bgcolor={colors.surface}
        className={classes.jumbotron}
        marginBottom={1}
        alignItems="center"
        display="flex"
      >
        <Container className={classes.container}>
          <Grid container direction="row-reverse" justify="center">
            <Grid item md sm={12} xs={12} className={classes.imageContainer}>
              <ScreenAndBook className={classes.image} />
            </Grid>
            <Grid item xs sm={9} md={7}>
              <Typography
                component="h1"
                variant="h1"
                className={classes.header}
              >
                SVÅRA ORD, LÄTT ATT FÖRSTÅ
              </Typography>
              <Typography className={classes.intro}>
                Genom förklaringar med bild och text ger Simpli alla goda
                chanser att förstå svåra ord i skolan.
              </Typography>
              <Grid container spacing={3}>
                {!appContext.user ? (
                  <>
                    <Grid item xs={6}>
                      <Button
                        color="primary"
                        fullWidth
                        className={classes.button}
                        onClick={() => history.push(routes.register.path)}
                      >
                        Testa nu
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        color="secondary"
                        fullWidth
                        className={classes.button}
                        onClick={() => history.push(routes.login.path)}
                      >
                        Logga in
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={6}>
                    <Button
                      color="primary"
                      fullWidth
                      className={classes.button}
                      onClick={() => history.push(routes.words.path)}
                    >
                      Starta
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box bgcolor={colors.primary} height={10} marginBottom={1}></Box>
    </Box>
  );
};

export const useLandingStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  header: {
    fontSize: 40,
    marginBottom: 40,

    [theme.breakpoints.down("md")]: {
      marginBottom: 20,
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      marginBottom: 20,
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: 25,
      marginBottom: 10,
    },
  },
  intro: {
    fontSize: 25,
    lineHeight: "45px",
    marginBottom: 40,
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      lineHeight: "35px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      lineHeight: "30px",
      marginBottom: 10,
    },
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 20,

    [theme.breakpoints.down("xs")]: {
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: 16,
    },
  },

  imageContainer: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
      marginBottom: 40,
    },

    [theme.breakpoints.down("xs")]: {
      marginBottom: 20,
    },
  },

  image: {
    position: "absolute",
    bottom: 0,
    right: 0,
    height: 450,

    "@media (max-height: 600px)": {
      height: 380,
    },

    [theme.breakpoints.down("sm")]: {
      transform: "translateX(50%)",
      right: "50%",
      height: 360,
    },

    [theme.breakpoints.down("xs")]: {
      height: 250,
    },
  },

  imageSpacer: {
    height: 150,

    "@media (max-height: 600px)": {
      height: 50,
    },

    [theme.breakpoints.down("sm")]: {
      height: 300,
    },

    [theme.breakpoints.down("xs")]: {
      height: 200,
    },
  },

  jumbotron: {
    paddingTop: 60,
    paddingBottom: 60,

    [theme.breakpoints.down("md")]: {
      paddingTop: 40,
      paddingBottom: 40,
    },

    [theme.breakpoints.down("sm")]: {
      paddingTop: 20,
      paddingBottom: 30,
    },

    [theme.breakpoints.down("xs")]: {
      paddingTop: 10,
    },
  },
}));
