import {
  Box,
  CircularProgress,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { generatePath, Link, RouteComponentProps } from "react-router-dom";
import { ErrorBox } from "../components/ErrorBox";
import { LookupHeader } from "../components/LookupHeader";
import { useFetchWords } from "../hooks/fetch";
import { routes } from "../routes";

export const LetterIndexPage = (
  props: RouteComponentProps<{ letter: string }>
) => {
  const letter = props.match.params.letter;

  return (
    <>
      <LookupHeader></LookupHeader>
      <Container>
        <Box mt={5}>
          <Typography align="center" variant="h1" gutterBottom>
            {letter.toUpperCase()}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <LetterWords letter={letter} />
        </Box>
      </Container>
    </>
  );
};

export const LetterWords = (props: { letter: string }) => {
  const letter = props.letter.length > 0 ? props.letter[0] : "";

  const { error, result: words, fetching } = useFetchWords();

  if (fetching) {
    return <CircularProgress />;
  }

  if (error) {
    return <ErrorBox>{error.message}</ErrorBox>;
  }

  if (!words) {
    return <ErrorBox>Kunde inte hitta några ord</ErrorBox>;
  }

  return (
    <Box display="flex" justifyContent="space-around" flexWrap="wrap">
      {words
        .filter(
          (x) => x.name[0].toLocaleLowerCase() === letter.toLocaleLowerCase()
        )
        .map((word, index) => (
          <WordBox key={index} word={word.name} id={word.id} />
        ))}
    </Box>
  );
};

const useWordBoxStyle = makeStyles((theme) => ({
  root: {
    width: 200,
    height: 50,
    background: theme.palette.secondary.main,
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 24,
    textDecoration: "none",
    "&:hover": {
      borderColor: "white",
    },
  },
}));

const WordBox = (props: { word: string; id: string }) => {
  const classes = useWordBoxStyle();
  return (
    <Link
      className={classes.root}
      to={generatePath(routes.word.path, { wordId: props.id })}
    >
      {props.word}
    </Link>
  );
};
