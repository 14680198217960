import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Container,
  Grid,
  Link,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { useFetchWords } from "../hooks/fetch";
import { routes } from "../routes";
import homeImageSrc from "../images/home.png";

const useLookupHeaderStyles = makeStyles((theme) => ({
  heading: {
    borderRadius: 0,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    display: "inline-block",
    padding: 15,
    fontSize: 30,
    fontWeight: "bold",
    fontFamily: theme.typography.h1.fontFamily,
  },
}));

export const LookupHeader = () => {
  const classes = useLookupHeaderStyles();
  return (
    <>
      <Box textAlign="center" mb={2}>
        <div className={classes.heading}>Simpli</div>
      </Box>
      <PageHeader>
        <Container maxWidth="md">
          <Grid container alignItems="center" spacing={4}>
            <Grid item>
              <RouterLink to={routes.words.path}>
                <img src={homeImageSrc} alt="Hem" style={{ height: 32 }}></img>
              </RouterLink>
            </Grid>
            <Grid item xs>
              <Search />
            </Grid>
          </Grid>
        </Container>
      </PageHeader>
    </>
  );
};

const Search = () => {
  const [search, setSearch] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  const { error, result, fetching } = useFetchWords();

  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showSearchResults) {
      const handleFocus = () => {
        setShowSearchResults(elRef.current!.contains(document.activeElement));
      };
      document.body.addEventListener("focusin", handleFocus);
      return () => document.body.removeEventListener("focusin", handleFocus);
    }
  }, [showSearchResults]);

  const searchResults = result?.filter((x) =>
    x.name.toLocaleLowerCase().startsWith(search.toLocaleLowerCase())
  );

  return (
    <ClickAwayListener
      onClickAway={() => setShowSearchResults(false)}
      mouseEvent="onMouseDown"
    >
      <div ref={elRef}>
        <Box>
          <TextField
            size="small"
            fullWidth
            variant="outlined"
            color="primary"
            label="Hitta ord"
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
            onFocus={() => setShowSearchResults(true)}
          ></TextField>
          <Box position="relative" height={1}>
            {search && showSearchResults ? (
              <Box
                position="absolute"
                top={5}
                left={0}
                right={0}
                p={2}
                minHeight={200}
                clone
                zIndex={1}
              >
                <Paper elevation={3} color="secondary">
                  {fetching ? <CircularProgress /> : null}
                  {error ? (
                    <Typography color="error"> Något gick fel</Typography>
                  ) : null}
                  {searchResults && searchResults.length === 0
                    ? "Hittade inga ord"
                    : null}
                  {searchResults && searchResults.length > 0 ? (
                    <>
                      <Box mb={1}>Hittade följande ord:</Box>
                      {searchResults.map((wordRef) => (
                        <Link
                          to={generatePath(routes.word.path, {
                            wordId: wordRef.id,
                          })}
                          component={RouterLink}
                          key={wordRef.id}
                        >
                          {wordRef.name}
                        </Link>
                      ))}
                    </>
                  ) : null}
                </Paper>
              </Box>
            ) : null}
          </Box>
        </Box>
      </div>
    </ClickAwayListener>
  );
};
