import { createContext, useContext } from "react";
import { User } from "./types";

export interface AppContextValue {
  update: React.Dispatch<React.SetStateAction<AppContextValue>>;
  user: User | null;
  cache: Readonly<{ [key: string]: any }>;
}

export const defaultAppContextValue: AppContextValue = {
  user: null,
  update: () => {},
  cache: {},
};

export const AppContext = createContext<AppContextValue>(
  defaultAppContextValue
);

export const useAppContext = () => {
  return useContext<AppContextValue>(AppContext);
};
