import { Box, Container, makeStyles } from "@material-ui/core";
import React from "react";
import { generatePath, Link as RouterLink } from "react-router-dom";
import { colors } from "../colors";
import { LookupHeader } from "../components/LookupHeader";
import { routes } from "../routes";

const letters = "abcdefghijklmnopqrstuvwxyzåäö".split("");

const useStyles = makeStyles((theme) => ({
  letterContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    backgroundColor: colors.surface,
    padding: 20,
    borderRadius: 20,
    marginTop: 30
  },
}));

export const WordsPage = () => {
  const classes = useStyles();
  return (
    <Box>
      <LookupHeader />
      <Container maxWidth="lg">
        <div className={classes.letterContainer}>
          {letters.map((c, index) => (
            <LetterBox key={c} letter={c} />
          ))}
        </div>
      </Container>
    </Box>
  );
};

const useLetterStyles = makeStyles({
  root: {
    width: 80,
    height: 100,
    margin: 10,
    borderRadius: 15,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: colors.secondary,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    flexDirection: "column",
    fontSize: 32,
    fontWeight: "bold",
    color: "white",
    textDecoration: "none",
    "&:hover": {
      borderColor: "white",
    },
  },
});

const LetterBox = (props: { letter: string }) => {
  const classes = useLetterStyles();
  return (
    <RouterLink
      className={classes.root}
      to={generatePath(routes.letter.path, { letter: props.letter })}
    >
      {props.letter.toUpperCase()}
      <Box height={4} bgcolor="white" width="40%" borderRadius={2} mb={1}></Box>
    </RouterLink>
  );
};
